/* You can add global styles to this file, and also import other style files */


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue color */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Error State */
  .error {
    color: red;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }